import React, { useCallback, useMemo, useState } from 'react';
import { DiscoverPageHeadStyled, DiscoverPageBodyStyled, DiscoverPageFooterStyled, DiscoverPageStyled } from './discoverPage.styled';
import DiscoverCard from './components/discoverCard/DiscoverCard';
import Stack from '@mui/material/Stack';
import { useLiveQuery } from 'dexie-react-hooks';
import AppBackdrop from '../../components/appBackdrop/AppBackdrop';
import useNotification from '../../hooks/useNotification';
import useIndexedDBService from '../../services/db/indexedDBService';
import Typography from '@mui/material/Typography';
import { exportJsonOrCSVFile } from '../../services/db/utils';
import { transformChartData } from './utils';
import { localStorageAxiosKey, localStorageModeKey, routes } from '../../constants';
import { defaultUploadDataFields } from '../../config/dexie';
import { useNavigate } from 'react-router-dom';
import StepButton from '../../components/stepButton/StepButton';
import useLocalStorage from '../../hooks/useLocalStorage';
import useEnabledNav from '../../hooks/useEnabledNav';

const defaultSelectedOptions = {
	[defaultUploadDataFields.infoNeed]: '',
	[defaultUploadDataFields.needId]: ''
};

const DiscoverPageContainer = () => {
	const { open } = useNotification();
	const navigate = useNavigate();
	const {
		getOptions,
		changeInfoNeed,
		getExportData,
		getDiscoverData
	} = useIndexedDBService();

	const [chartAxiosValues] = useLocalStorage(localStorageAxiosKey, null);
	const [isDemoMode] = useLocalStorage(localStorageModeKey, true);
	const [isOriginalBubblesSize, setIsOriginalBubblesSize] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState(defaultSelectedOptions);

	const discoverOptions = useLiveQuery(getOptions);
	const filteredInfoNeedQueryResult = useLiveQuery(
		() => getDiscoverData(selectedOptions),
		[selectedOptions]
	);

  const [, addNavItem] = useEnabledNav();

	const onChangeFilter = useCallback(({ target }) => {
		const name = target.name;
		const value = target.value;

		const { needId } = defaultUploadDataFields;

		if (!value.toString()) {
			return setSelectedOptions(defaultSelectedOptions);
		}

		const selectedOption = discoverOptions.find(option => option[name] === value);
		setSelectedOptions(() => ({
			...selectedOption,
			[needId]: selectedOption[[needId]].toString()
		}));
	}, [discoverOptions]);

	const onDownloadFile = useCallback((format) => {
		getExportData()
			.then(dataToExport => exportJsonOrCSVFile(dataToExport, format))
			.catch(err => open({ message: err.message, variant: 'error' }));
	}, [getExportData, open]);

	const onInfoNeedEdit = useCallback((value) => {
		const { infoNeed, needId } = defaultUploadDataFields;

		changeInfoNeed(
			selectedOptions[infoNeed],
			+selectedOptions[needId],
			value
		)
			.then(() => {
				setSelectedOptions(
					prevSelectedOptions => ({ ...prevSelectedOptions, [infoNeed]: value })
				);
				open({ message: 'Information Exigent Label Changed', variant: 'success' });
			});
	}, [selectedOptions, changeInfoNeed, open]);

	const tableHeadings = useMemo(() => ([
		{
			id: 'searchTerm',
			numeric: false,
			disablePadding: true,
			label: 'Search Query'
		},
		{
			id: 'needProbability',
			numeric: true,
			disablePadding: true,
			label: 'Assignment Probability'
		}
	]), []);

	const onToggleOriginalBubblesSize = useCallback(() => {
		setIsOriginalBubblesSize(prev => !prev);
	}, []);

	const chartData = useMemo(
		() => transformChartData(filteredInfoNeedQueryResult?.chartData, isOriginalBubblesSize, selectedOptions),
		[filteredInfoNeedQueryResult, isOriginalBubblesSize, selectedOptions]
	);

	const infoOptions = useMemo(
		() => {
			if (discoverOptions) {
				const { infoNeed, needId } = defaultUploadDataFields;
				return {
					infoNeedOptions: discoverOptions.map(item => item[infoNeed]),
					infoNeedIdOptions: discoverOptions.map(item => item[needId])
				};
			}
		},
		[discoverOptions]
	);

	if (!(discoverOptions && chartData)) {
		return <AppBackdrop open={!(discoverOptions && chartData)}/>;
	}

  const navidateTargetAudience = () => {
    addNavItem(routes.targetAudience)
    navigate(routes.targetAudience)
  }

	return (
		<DiscoverPageStyled>
			<DiscoverPageHeadStyled>
				<Typography textAlign="center" variant="h4" component="h1">
                        Explore the Information Exigencies
				</Typography>
				<Typography textAlign="center" variant="p" component="p">
                        <br />To start, you can explore the identified information exigencies and their underlying search queries. <br />The right panel includes an interactive map of all recorded search queries, the left panel allows to inspect individual exigencies.
				</Typography>
			</DiscoverPageHeadStyled>
			<DiscoverPageBodyStyled>
				<DiscoverCard
					selectedInfoNeedId={selectedOptions[defaultUploadDataFields.needId]}
					selectedInfoNeed={selectedOptions[defaultUploadDataFields.infoNeed]}
					infoNeedOptions={infoOptions.infoNeedOptions}
					infoNeedIdOptions={infoOptions.infoNeedIdOptions}
					tableRows={filteredInfoNeedQueryResult?.tableRowsData}
					{...{
						tableHeadings,
						chartData,
						chartAxiosValues,
						onInfoNeedEdit,
						onDownloadFile,
						isDemoMode,
						onChangeFilter,
						onToggleOriginalBubblesSize,
						isOriginalBubblesSize
					}}
				/>
			</DiscoverPageBodyStyled>

			<DiscoverPageFooterStyled>
				<Stack direction='row' justifyContent='center' spacing={3}>
					<StepButton onClick={() => navigate(routes.statistic)} label='Back: Data Overview' color='warning'/>
					<StepButton onClick={navidateTargetAudience} label='Next: Define Target Audience'/>
				</Stack>
			</DiscoverPageFooterStyled>

		</DiscoverPageStyled>
	);
};

export default DiscoverPageContainer;