import React, { useCallback, useState } from 'react';
import { MainPageBodyStyled, MainPageHeadStyled, MainPageStyled } from './mainPage.styled';
import { Stack } from '@mui/material';
import MainHead from './components/mainHead/MainHead';
import DropZone from '../../components/dropZone/DropZone';
import UploadTestFiles from '../../components/uploadTestFiles/UploadTestFiles';
import AppBackdrop from '../../components/appBackdrop/AppBackdrop';
import UploadCard from './components/uploadCard/UploadCard';
import validateFile from './utils/validateFile';
import useNotification from '../../hooks/useNotification';
import DownloadFile from './components/downloadFile/DownloadFile';
import { useNavigate } from 'react-router-dom';
import { defineTypeDialogSubtitle, defineTypeDialogTitle, localStorageModeKey, routes } from '../../constants';
import useIndexedDBService from '../../services/db/indexedDBService';
import readJsonFile from './utils/readFile';
import DefineTypeDialog from './components/defineTypeDialog/DefineTypeDialog';
import getFieldsForTyping from './utils/getUploadedFields';
import transformTypedFields from './utils/transformTypedFields';
import useWarnUserAboutDBCreation from './utils/warnUserAboutDBCreation';
import useLocalStorage from '../../hooks/useLocalStorage';

const MainPageContainer = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [uploadedRespondents, setUploadedRespondents] = useState(null);
	const [uploadedFields, setUploadedFields] = useState(null);
	const { userAgreesDBCleanup } = useWarnUserAboutDBCreation();
	const [_, setAppMode] = useLocalStorage(localStorageModeKey, true);

	const navigate = useNavigate();
	const { setUploadDataToDb, clearData } = useIndexedDBService();

	const { open } = useNotification();

	const onCloseDialog = () => {
		setUploadedFields(() => null);
	};

	const onUploadRespondentsToDB = (fieldsInfo) => {
		setIsLoading(() => true);
		setUploadDataToDb(uploadedRespondents, fieldsInfo)
			.then(() => {
				setAppMode(false);
				open({ message: 'File successfully download', variant: 'success' });
				navigate(routes.statistic);
			})
			.catch(err => open({ message: err.message, variant: 'error' }))
			.finally(() => setIsLoading(() => false));
	};

	const onDropAccepted = useCallback(async (acceptedFiles) => {
		try {
			const file = acceptedFiles[0];

			if (!file) {
				return;
			}

			const isDBCanCreated = await userAgreesDBCleanup();
			if (!isDBCanCreated) {
				return;
			}

			setIsLoading(true);

			await clearData();
			const respondents = await readJsonFile(file);
			const validateErrorMessage = validateFile(respondents);

			setIsLoading(false);

			if (validateErrorMessage) {
				return open({ message: validateErrorMessage, variant: 'error' });
			}

			const uploadedFields = getFieldsForTyping(respondents);
			setUploadedRespondents(() => respondents);
			setUploadedFields(() => uploadedFields);
		} catch (err) {
			setIsLoading(false);
			open({ message: err.message, variant: 'error' });
		}
	}, [setIsLoading, open, userAgreesDBCleanup, clearData]);

	const onTestFileLoad = useCallback(async () => {
		try {
			const isDBCanCreated = await userAgreesDBCleanup();
			if (!isDBCanCreated) {
				return;
			}

			setIsLoading(true);
			await clearData();

			const { default: demoRespondents } = await import((`../../assets/TAVIX_input_invest.json`));
			const uploadedFields = getFieldsForTyping(demoRespondents);
			const fieldsInfo = transformTypedFields(uploadedFields);

			await setUploadDataToDb(demoRespondents, fieldsInfo);

			setAppMode(true);
			open({ message: 'File successfully download', variant: 'success' });
			navigate(routes.statistic);
		} catch (err) {
			open({ message: err.message, variant: 'error' });
		} finally {
			setIsLoading(() => false);
		}
	}, [open, navigate, setUploadDataToDb, userAgreesDBCleanup, clearData, setAppMode]);

	const spinner = isLoading && <AppBackdrop open={!!isLoading}/>;

	return (
		<MainPageStyled>
			<DefineTypeDialog
				open={!!uploadedFields}
				formFields={uploadedFields}
				onClose={onCloseDialog}
				onSubmit={onUploadRespondentsToDB}
				uploadBtnLabel='Upload data'
				cancelBtnLabel='Cancel'
				title={defineTypeDialogTitle}
				subtitle={defineTypeDialogSubtitle}
			/>
			{spinner}
			<MainPageHeadStyled>
				<MainHead
					mainTitle='TAVIX'
					mainSubtitle='Targeting via Information Exigencies'
					subtitle='A research-driven app to interactively create targeted search campaigns'
					info='To get started:'
				/>
			</MainPageHeadStyled>
			<MainPageBodyStyled>
				<Stack spacing={4} direction='row' justifyContent='space-between'>
					<UploadCard>
						<Stack spacing={3} flexGrow={1}>
							<DownloadFile
								title='Get a sample file, by clicking the link below'
								json={{
									label: 'Download sample file',
									href: `${process.env.PUBLIC_URL}/assets/testdata_prepared.json`
								}}
							/>
							<DropZone
								onDropAccepted={onDropAccepted}
								actionLabel='Drop the files here ...'
								label='Drag & drop your own data here, or click to select a file'
								description='(Only .json files not exceeding 40Mb are accepted)'
							/>
						</Stack>
					</UploadCard>
					<UploadCard>
						<Stack spacing={4} alignItems='center' width='100%'>
							<UploadTestFiles
								title='Or test the app in demo mode'
								uploadBtnLabel='Start Demo Mode'
								onTestFileLoad={onTestFileLoad}
							/>
						</Stack>
					</UploadCard>
				</Stack>
			</MainPageBodyStyled>
		</MainPageStyled>
	);
};

export default MainPageContainer;