import React from 'react';
import MuiSwitch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const Switch = (props) => {
    const { label, defaultChecked = true, checked, ...restProps } = props;

    const isCheckedPassed = checked !== undefined;
    const internalDefaultChecked = isCheckedPassed ? undefined : defaultChecked;

    return (
        <FormControlLabel
            control={
                <MuiSwitch
                    defaultChecked={internalDefaultChecked}
                    checked={checked}
                    {...restProps}
                />
            }
            label={label}
        />
    );
};

export default Switch;
