import { boolAttributesName, categoricalAttributesName } from '../../../constants';

const detectShouldApplyFilter = (filter, respondent) => {
    const filtersWithBoolAttributeNames = filter.reduce((prevValue, currValue) => {
        const { type, name } = currValue;

        if (type === boolAttributesName) {
            return [name, ...prevValue];
        }

        return prevValue;
    }, []);

    return filtersWithBoolAttributeNames.some((name) => {
        return !!respondent[name];
    });
};

const isRespondentMatchesTargetAudienceParam = (respondent, filter, isORFilterType = false) => {
    return filter.every(({ type, name, value }) => {
        const respondentAttrValue = respondent[name];

        switch (type) {
            case boolAttributesName:
                return isORFilterType
                    ? detectShouldApplyFilter(filter, respondent)
                    : !!respondentAttrValue;
            case categoricalAttributesName:
                return value.includes(respondentAttrValue);
            default:
                return respondentAttrValue >= value[0] && respondentAttrValue <= value[1];
        }
    });
};

export default isRespondentMatchesTargetAudienceParam;
