import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const DiscoverBubblesStyled = styled(Stack, {
	name: 'DiscoverBubbles',
	slot: 'Root'
})({});

export const DiscoverBubblesChartStyled = styled(Box, {
	name: 'DiscoverBubblesChart',
	slot: 'Root',
  })({
	display: 'flex', // Make it a flex container
	justifyContent: 'center', // Center children horizontally
	alignItems: 'center', // Center children vertically (if needed)
	position: 'relative',
	width: '100%', // Ensure it takes the full width of its parent
	height: '100%', // Ensure it has a defined height to allow vertical centering
	// ... any other styles you want to apply
  });

export const DiscoverBubblesLegendStyled = styled(Stack, {
	name: 'DiscoverBubblesLegend',
	slot: 'Root'
})({
	padding: '2rem'
});

export default DiscoverBubblesStyled