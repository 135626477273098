import { useLiveQuery } from 'dexie-react-hooks';
import useIndexedDBService from '../../services/db/indexedDBService';
import AppBackdrop from '../../components/appBackdrop/AppBackdrop';
import React, { useCallback, useMemo, useState } from 'react';
import RevealInformationPageStyled, {
    RevealInformationPageBodyStyled,
    RevealInformationPageFooterStyled,
    RevealInformationPageHeadStyled,
    RevealInformationPageLegendStyled,
} from './revealInformationPage.styled';
import Typography from '@mui/material/Typography';
import RevealCard from './components/revealCard/RevealCard';
import useLocalStorage from '../../hooks/useLocalStorage';
import {
    boolAttributesName,
    categoricalAttributesName,
    localStorageDefineTargetAudienceFilter,
    localStorageSearchTermSetKey,
    rangeAttributesName,
    routes,
} from '../../constants';
import ChartLegend from './components/chartLegend/ChartLegend';
import RevealDialog from './components/revealDialog/RevealDialog';
import { transformBarChartData } from './utils/transformBarChartData';
import Stack from '@mui/material/Stack';
import StepButton from '../../components/stepButton/StepButton';
import { useNavigate } from 'react-router-dom';
import transformAudienceFilter from '../utils/transformAudienceFilter';

const legendItems = [
    { label: 'Share of Consumers in Target Audience (for this IX)', labelComponent: 'bar' },
    {
        label: 'Share of Consumers in Target Audience (across all IXs).',
        labelComponent: 'line',
    },
    { label: 'Standard Error of the Mean', labelComponent: 'standardError' },
];

const RevealInformationPageContainer = () => {
    const { getRevealPageData } = useIndexedDBService();
    const targetAudienceInformation = useLiveQuery(getRevealPageData);

    const [searchTermSet] = useLocalStorage(localStorageSearchTermSetKey, []);
    const [audienceFilter] = useLocalStorage(localStorageDefineTargetAudienceFilter, null);

    const [selectedInfoNeedItem, setSelectedInfoNeedItem] = useState(null);

    const navigate = useNavigate();

    const barChartData = useMemo(
        () => transformBarChartData(targetAudienceInformation),
        [targetAudienceInformation]
    );

    const transformedAudienceFilter = useMemo(() => {
        if (audienceFilter) {
            return transformAudienceFilter(audienceFilter.filterItems);
        }
    }, [audienceFilter]);

    const onBarItemClick = useCallback((infoNeedData) => {
        setSelectedInfoNeedItem(() => infoNeedData);
    }, []);

    const onDialogClose = () => {
        setSelectedInfoNeedItem(null);
    };

    if (!barChartData) {
        return <AppBackdrop open={!barChartData} />;
    }

    return (
        <>
            {selectedInfoNeedItem && (
                <RevealDialog
                    open={!!selectedInfoNeedItem}
                    selectedInfoNeedId={selectedInfoNeedItem.infoNeedId}
                    title={`Select from the Search Queries Corresponding to "${selectedInfoNeedItem.infoNeed}"`}
                    onClose={onDialogClose}
                />
            )}
            <RevealInformationPageStyled>
                <RevealInformationPageHeadStyled>
                    <Typography textAlign="center" variant="h4" component="h1">
                        Identify Your Target Audience's Specific Information Exigencies
                    </Typography>
                    <Typography textAlign="center" variant="p" component="p">
                        <br />From top to bottom, the following graph displays the information exigencies whose corresponding search queries are most indicative of your target audience.
                        <br />Click on a bar to view its corresponding search queries and add them to your query set for targeting.
				            </Typography>

                </RevealInformationPageHeadStyled>

                <RevealInformationPageBodyStyled>
                    <RevealCard
                        onBarItemClick={onBarItemClick}
                        barChartData={barChartData}
                        infoTitle="Target Audience"
                        rangeInfoTitle="Range Selections"
                        categoricalInfoTitle="Categorical Selections"
                        boolInfoTitle="Binary Selections"
                        categoricalAttr={
                            transformedAudienceFilter?.[categoricalAttributesName] || []
                        }
                        booleanAttr={transformedAudienceFilter?.[boolAttributesName] || []}
                        rangeAttr={transformedAudienceFilter?.[rangeAttributesName] || []}
                    />
                </RevealInformationPageBodyStyled>

                <RevealInformationPageLegendStyled>
                    <ChartLegend items={legendItems} />
                </RevealInformationPageLegendStyled>

                <RevealInformationPageFooterStyled>
                    <Stack direction="row" justifyContent="center" spacing={3}>
                        <StepButton
                            onClick={() => navigate(routes.targetAudience)}
                            label="Back: Define Target Audience"
                            color="warning"
                        />
                        <StepButton
                            disabled={!searchTermSet.length}
                            onClick={() => navigate(routes.buildSearchTermSet)}
                            label="Next: Build Query Set"
                        />
                    </Stack>
                </RevealInformationPageFooterStyled>
            </RevealInformationPageStyled>
        </>
    );
};

export default RevealInformationPageContainer;
