import color from 'randomcolor';
import { setValueToLocalStorage } from '../../../../services/localStorage/localStorageService';
import { localStorageAxiosKey } from '../../../../constants';
// import { alpha } from '@mui/material';

// Updated
// export const getColorGenerator = () => {
// 	const hash = {};
//
// 	return (id) => {
// 		if (hash[id]) {
// 			return hash[id];
// 		}
//
// 		const mainColor = color({
// 			luminosity: 'bright',
// 			alpha: 1,
// 		});
//
// 		hash[id] = {
// 			backgroundColor: alpha(mainColor, 0.4),
// 			borderColor: mainColor
// 		};
// 		return hash[id];
// 	};
// };

// old
const predefinedColors = [
	'#1f77b4',
	'#aec7e8',
	'#ff7f0e',
	'#ffbb78',
	'#2ca02c',
	'#98df8a',
	'#d62728',
	'#ff9896',
	'#9467bd',
	'#c5b0d5',
	'#8c564b',
	'#c49c94',
	'#e377c2',
	'#f7b6d2',
	'#7f7f7f',
	'#c7c7c7',
	'#bcbd22',
	'#dbdb8d',
	'#17becf',
	'#9edae5'	// ...add more colors as needed
  ];
  
  export const getColorGenerator = () => {
	const hash = {};
  
	return (id) => {
	  if (hash[id]) {
		return hash[id];
	  }
  
	  // Use the id to index into the predefinedColors array.
	  // Use modulo operator to loop back around if there are more ids than colors.
	  const mainColor = predefinedColors[id % predefinedColors.length];
  
	  hash[id] = {
		backgroundColor: mainColor,
		borderColor: mainColor,
	  };
  
	  return hash[id];
	};
  };

export const transformLegendItems = (items) => {
	const legendItems = items.reduce((acc, item) => {
		const infoNeedId = item.text.infoNeedId;
		const currentLegendItem = acc[infoNeedId];

		if (!currentLegendItem) {
			return {
				...acc,
				[infoNeedId]: {
					...item,
					datasetIndexes: [item.datasetIndex]
				}
			};
		}

		return {
			...acc,
			[infoNeedId]: {
				...item,
				datasetIndexes: [...currentLegendItem.datasetIndexes, item.datasetIndex]
			}
		};
	}, {});

	return Object.values(legendItems);
};

const contentHasAxiosValue = (respondents) => {
	return respondents.every(respondent => {
		const contentX = respondent.X;
		const contentY = respondent.Y;

		return contentX && contentY;
	});
};

export const setMinMaxAxiosValueToStorage = (respondents = []) => {
	const respondentsHaveAxiosValue = contentHasAxiosValue(respondents);

	if (!respondentsHaveAxiosValue) {
		return setValueToLocalStorage(localStorageAxiosKey, null);
	}

	let minX = Infinity;
	let maxX = -Infinity;
	let minY = Infinity;
	let maxY = -Infinity;

	respondents.forEach(respondent => {
		const axiosX = respondent.X;
		const axiosY = respondent.Y;

		minX = Math.min(minX, axiosX);
		maxX = Math.max(maxX, axiosX);
		minY = Math.min(minY, axiosY);
		maxY = Math.max(maxY, axiosY);
	});

	const maxRange = Math.max(maxX - minX, maxY - minY);
	maxX = minX + maxRange;
	maxY = minY + maxRange;
	
	minY = minY === 0 ? minY : minY - 5;
	minX = minX === 0 ? minX : minX - 5;
	maxY += 5;
	maxX += 5;

	const storageValue = {
		y: { min: minY, max: maxY },
		x: { min: minX, max: maxX }
	};

	return setValueToLocalStorage(localStorageAxiosKey, storageValue);
};