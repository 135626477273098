export const routes = {
    home: '/',
    statistic: '/statistic',
    discover: '/discover',
    targetAudience: '/target-audience',
    revealInformation: '/reveal-information',
    mostRelevantSearchTerm: '/most-relevant-search-term',
    buildSearchTermSet: '/build-search-term-set',
};

export const navigations = [
    { path: routes.home, label: 'Home' },
    { path: routes.statistic, label: 'Data Overview' },
    { path: routes.discover, label: 'Explore Data' },
    { path: routes.targetAudience, label: 'Define Target Audience' },
    { path: routes.revealInformation, label: 'Identify Specific IXs' },
    { path: routes.buildSearchTermSet, label: 'Build Query Set' },
];

export const mobileDeviceResolution = 768;

// MAIN PAGE
export const localStorageModeKey = 'isDemoMode';
export const localStorageAxiosKey = 'axiosValue';
export const localStorageNavigationsKey = 'navigations';
export const possibleDataTypes = ['string', 'number', 'boolean'];
export const discoverExportFileName = 'TAVIX_result';
export const fileMaxSize = 41943040;
export const CSVFormat = { name: 'csv', format: 'text/csv', variants: ['.csv'] };
export const JSONFormat = { name: 'json', format: 'application/json', variants: ['.json'] };
export const formFieldNameHelperText = 'Name of the field specified in the loaded file';
export const formFieldTypeHelperText =
    'The field type is defined automatically based on the loaded data.';
export const formFieldLabelHelperText =
    'The name of the field formed automatically on the basis of the loaded data. If the name is not defined correctly, you can change it. Avoid identical names';
export const formFieldCategoricalHelperText =
    'A flag that indicates whether the field is categorical or not. Note that not all fields describing a respondent are categorical, but only fields such as age, education level, and similar. Fields with values 0-1 (boolean type) are NOT categorical';

// IDENTIFY FILTER HELPER TEXT
export const identifyFilterRangeHelperText =
    'If you change the range, the table will contain search queries whose "Assignment Probability" parameter is included in the range. The default range is 0 to 100%. These values indicate how clearly each search query belongs to the selected information exigency.';
export const identifyFilterTermNumberHelperText =
    'Show top 100 (by default) search queries, ordered by their assignment probability.';
export const identifyFilterTermNameInputHelperText = 'Start typing the name of the search query';

// DISCOVER PAGE
export const MAX_RAD = 50;
export const discoverInteractiveMapTitle = 'Interactive Map of Search Queries';
export const discoverInteractiveMapSubtitle =
    ['Each point represents a search query, colored by information exigency;', <br />,'Proximitiy indicates the semantic similarity of the search query;', <br />, 'Size indicates the number of occurrenes in the data\n'];
export const discoverInteractiveMapLegendContainerId = 'legend-container';
export const discoverInteractiveMapDisableMessage =
    'To display the interactive map, please specify X and Y coordinates in the downloaded file';
export const defineTypeDialogTitle = 'Please check carefully the correctness of certain data types';
export const defineTypeDialogSubtitle =
    'If the field is of a categorical type, such as age, gender, education level, etc., then check the "Categorical" box';
export const tooltipSearchTermBeforeTitle = 'Search Query:'; 
export const tooltipSearchTermBeforeLabel = 'Info Exigency:';
export const tooltipSearchTermBeforeFooter = 'Probability:';
export const filterHelpText =
    'Using filters, you will be able to select the desired searches by "Information Exigency" or "Exigency ID"';
export const editInfoNeedLabelHelperText =
    'Click on the handle icon to change the name of the selected information exigency. You will then be able to export the file with the changes applied to all information exigencies ';

// DEFINE TARGET AUDIENCE PAGE
export const localStorageDefineTargetAudienceAttrKey = 'attributes';
export const audienceDefinitionsStorageKey = 'audienceDefinitions';
export const localStorageDefineTargetAudienceFilter = 'audienceFilter';
export const localStorageEnableORFilterTypeKey = 'enableORFilterType';
export const boolAttributesName = 'booleanAttributes';
export const categoricalAttributesName = 'categoricalAttributes';
export const rangeAttributesName = 'rangeAttributes';
export const minRangeDistanceOfDTAPage = 5;
export const respondentAttributesHelperText =
    'You can define the target audience by selecting desired characteristics from the available characteristics below';

// REVEAL AUDIENCE INFORMATION NEEDS PAGE
export const audienceChartTitle = 'Information Exigencies (IXs), Ranked by Share of Consumers in Target Audience';
export const tooltipStandardErrorTitle = 'Standard error of the mean';
export const barChartItemsLimit = 20;
export const minRangeDistanceOfRAINPage = 5;
export const rangeProbabilityFilterName = 'rangeProbability';
export const topSearchTermFilterName = 'topSearchTerm';
export const searchTermNameFilterName = 'searchTermName';
export const rowsPerPageOnRevealTable = 10;
export const localStorageSearchTermSetKey = 'searchTermSet';
export const REVEAL_DIALOG_DEFAULT_FILTERS = {
    [rangeProbabilityFilterName]: [0, 100],
    [topSearchTermFilterName]: 100,
    [searchTermNameFilterName]: '',
};

// IDENTIFY MOST RELEVANT SEARCH TERM POPUP
export const addToSearchTermsSetHelperText =
    'Add the selected search queries to the final set of queries';

// BUILD SEARCH TERM SET PAGE
export const rowsPerPageOnBuildTable = 10;
export const BUILD_SET_PAGE_DEFAULT_FILTERS = {
    [rangeProbabilityFilterName]: [0, 100],
    [topSearchTermFilterName]: 100,
    [searchTermNameFilterName]: '',
};
export const removeSelectedTermsHelperText =
    'Remove selected search queries from the final set of queries';
export const exportSearchTermsSetHelperText = 'Export the set of queries in csv format';
export const finalExportFileName = 'TAVIX_final_result';

// DEFAULT VALUES
export const IS_OR_FILTER_TYPE_ENABLED = false;
